import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=62cd6bd6&"
import script from "./Article.vue?vue&type=script&lang=ts&"
export * from "./Article.vue?vue&type=script&lang=ts&"
import style0 from "./Article.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdaptiveImageNew: require('/tmp/build_d38f6897/src/components/media/AdaptiveImageNew.vue').default,ButtonCircle: require('/tmp/build_d38f6897/src/components/button/Circle.vue').default})
