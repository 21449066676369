import { render, staticRenderFns } from "./HorizontalList.vue?vue&type=template&id=099f7813&"
import script from "./HorizontalList.vue?vue&type=script&lang=ts&"
export * from "./HorizontalList.vue?vue&type=script&lang=ts&"
import style0 from "./HorizontalList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/tmp/build_d38f6897/src/components/button/Button.vue').default,CardShow: require('/tmp/build_d38f6897/src/components/card/Show.vue').default,HorizontalScrollSlider: require('/tmp/build_d38f6897/src/components/layout/HorizontalScrollSlider.vue').default,CardWorker: require('/tmp/build_d38f6897/src/components/card/Worker.vue').default,CardArticle: require('/tmp/build_d38f6897/src/components/card/Article.vue').default,BlockWrapper: require('/tmp/build_d38f6897/src/components/block/BlockWrapper.vue').default})
